import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';

const FloatingButtons = () => {
    const [isPulsing, setIsPulsing] = useState(false);

    // Function to handle conversion reporting
    const gtag_report_conversion = (url) => {
        const callback = function () {
            if (typeof (url) !== 'undefined') {
                window.location = url;
            }
        };

        window.gtag('event', 'conversion', {
            'send_to': 'AW-16721393829/tqt9CO2enOEZEKXxsKU-', 
            'value': 1.0,
            'currency': 'AED',
            'event_callback': callback
        });
        return false;
    };

    useEffect(() => {
        const pulseTimeout = setTimeout(() => {
            setIsPulsing(true);
        }, 1000);

        return () => clearTimeout(pulseTimeout);
    }, []);

    const buttonClasses = `
        p-4 rounded-full shadow-lg text-white
        transition-all duration-300 ease-in-out
        hover:scale-110 hover:shadow-xl
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50
    `;

    return (
        <div className="fixed bottom-16 right-6 flex flex-col space-y-4 z-50">
            <a
                href="https://wa.me/971561868236?text=Hello!"
                target="_blank"
                rel="noopener noreferrer"
                className={`${buttonClasses} bg-green-500 focus:ring-green-400 ${isPulsing ? 'animate-pulse' : ''}`}
                onClick={() => gtag_report_conversion('https://wa.me/971561868236?text=Hello!')}
            >
                <FaWhatsapp className="w-8 h-8" />
            </a>

            <a
                href="tel:+971561868236"
                className={`${buttonClasses} bg-cyan-500 focus:ring-blue-400`}
                onClick={() => gtag_report_conversion('tel:+971561868236')}
            >
                <FaPhone className="w-8 h-8" />
            </a>
        </div>
    );
};

export default FloatingButtons;
