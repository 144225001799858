import React from "react";
import { Helmet } from "react-helmet";
import { FaBriefcase } from "react-icons/fa";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import ProfPrice from "../../assets/prof-price.jpg";

const VehicleAppraisalsForBanks = () => {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    return (
        <div className="container mx-auto p-6 pt-24">
            <Helmet>
                <html lang={currentLang} />
                <title>{t('vehicleAppraisalsMetaTitle')}</title>
                <meta name="description" content={t('vehicleAppraisalsMetaDescription')} />
                <meta name="keywords" content={t('vehicleAppraisalsMetaKeywords')} />
                <meta name="author" content={t('vehicleAppraisalsMetaAuthor')} />
            </Helmet>

            <div className="flex flex-col lg:flex-row gap-12 items-center">
                {/* Text Section */}
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="bg-white shadow-xl rounded-lg p-8"
                    >
                        <div className="flex items-center mb-6">
                            <FaBriefcase className="text-blue-500 w-14 h-14 mr-6" />
                            <h1 className="text-4xl font-extrabold text-gray-900">
                                {t('vehicleAppraisalsTitle')}
                            </h1>
                        </div>
                        <p
                            className="text-base text-gray-700 leading-7 mt-4"
                            dangerouslySetInnerHTML={{ __html: t('vehicleAppraisalsDescription') }}
                        />
                    </motion.div>
                </div>

                {/* Image Section */}
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.85 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.6 }}
                    >
                        <img
                            src={ProfPrice}
                            alt={t('vehicleAppraisalsTitle')}
                            className="w-full h-auto max-w-md max-h-96 rounded-lg shadow-lg hover:scale-105 transform transition-transform duration-300"
                        />
                    </motion.div>
                </div>
            </div>

            {/* Features Section */}
            <div className="mt-12 bg-gray-50 p-8 rounded-lg shadow-md">
                <h2 className="text-3xl font-bold text-gray-900">
                    {t('vehicleAppraisalsIncludesTitle')}
                </h2>
                <ul className="list-disc list-inside text-base text-gray-800 mt-6 space-y-4">
                    <li>{t('comprehensiveAssessment')}</li>
                    <li>{t('marketValueAnalysis')}</li>
                    <li>{t('conditionHistoryReports')}</li>
                    <li>{t('loanToValueCalculations')}</li>
                </ul>
            </div>
        </div>
    );
};

export default VehicleAppraisalsForBanks;

// new push third commit 