import React from 'react';
import { Helmet } from 'react-helmet';
import CarImg from '../../assets/hero.png';
import CarProfImg from '../../assets/road-simu.png';
const AbuDhabi = () => {
    return (
        <div className="bg-gray-100 text-gray-800">
            <Helmet>
                <title>مركز فحص السيارات في أبوظبي - فحص شامل للسيارة وفحص السيارة قبل الشراء</title>
                <meta
                    name="description"
                    content="أفضل مركز فحص السيارات ابوظبي يقدم خدمات فحص شامل للسيارة وفحص سيارة قبل الشراء. ضمان الجودة والدقة لتوفير راحة البال عند شراء سيارة مستعملة."
                />
                <meta name="keywords" content="فحص شامل للسيارة, مركز فحص السيارات ابوظبي, فحص سيارة قبل الشراء, خدمات فحص السيارات, أبوظبي" />
                <link rel="canonical" href="https://www.yourwebsite.com/abu-dhabi-car-inspection" />
                <meta property="og:title" content="مركز فحص السيارات في أبوظبي - خدمات فحص شاملة" />
                <meta property="og:description" content="اكتشف أفضل خدمات فحص السيارات في أبوظبي. فحص شامل للسيارة وفحص قبل الشراء لضمان جودة واعتمادية سيارتك." />
                <meta property="og:image" content="https://www.yourwebsite.com/images/abu-dhabi-car-inspection-center.jpg" />
                <meta property="og:url" content="https://www.yourwebsite.com/abu-dhabi-car-inspection" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            <header className="bg-blue-600 text-white py-8 text-center">
                <h1 className="text-4xl font-bold mb-2">مركز فحص السيارات في أبو ظبي</h1>
                <p className="text-xl mt-2">نقدم خدمات فحص شامل للسيارة وفحص السيارة قبل الشراء لضمان راحتك وجودة اختيارك</p>
            </header>

            <main className="py-12 px-4 max-w-5xl mx-auto">
                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-blue-600 mb-6">خدماتنا المتميزة</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div>
                            <p className="mb-4">
                                نحن في مركز البروفيسور نفخر بتقديم مجموعة شاملة من الخدمات المتعلقة بفحص السيارات. نهدف إلى توفير أعلى مستويات الجودة والدقة لعملائنا الكرام في أبوظبي والمناطق المحيطة.
                            </p>
                            <p className="mb-4">
                                تشمل خدماتنا المتخصصة ما يلي:
                            </p>
                            <ul className="list-disc list-inside mt-4 space-y-2">
                                <li>فحص شامل للسيارة لكشف أي أعطال محتملة</li>
                                <li>فحص سيارة قبل الشراء لضمان حالتها وجودتها</li>
                                <li>تقديم تقرير مفصل وشامل عن حالة السيارة</li>
                                <li>فحص الأنظمة الإلكترونية والكهربائية</li>
                                <li>تقرير فحص يتضمن 260 نقطة </li>
                                <li>تقييم نظام الفرامل </li>
                                <li>تحليل نظام التوجيه والتعليق </li>
                                <li>حالة المظهر الخارجي والداخلي</li>
                                <li>اختبار قيادة شامل</li>
                                <li>فحص شامل للسيارة</li>
                                <li>فحص ما بعد إصلاح المركبات</li>
                                <li>تقييم سعر المركبة</li>
                            </ul>
                        </div>
                        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center">
                            <img src="https://lh5.googleusercontent.com/p/AF1QipNYCLu4d3SFWbPjyp5jMbrxAD_ZBeoZqCB8hV8e=w408-h724-k-no" alt="مركز فحص البروفيسور لفحص السيارات في أبو ظبي" className="max-w-full h-auto rounded" />
                        </div>
                    </div>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-blue-600 mb-6">فوائد الفحص الشامل</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center">
                            <img src={CarProfImg} alt="فوائد فحص السيارات" className="max-w-full h-auto rounded" />
                        </div>
                        <div>
                            <p className="mb-4">
                                عند اختيارك لمركز فحص السيارات في ابوظبي، ستحصل على مجموعة من الفوائد التي تضمن لك تجربة شراء آمنة ومريحة:
                            </p>
                            <ul className="list-disc list-inside mt-4 space-y-2">
                                <li>تقرير دقيق يساعدك في اتخاذ قرار شراء مستنير</li>
                                <li>الكشف عن أي أعطال أو مشكلات مخفية في السيارة</li>
                                <li>ضمان الاستثمار في سيارة موثوقة وآمنة</li>
                                <li>توفير المال على المدى الطويل من خلال تجنب المشاكل المستقبلية</li>
                                <li>زيادة الثقة في عملية شراء السيارات المستعملة</li>
                            </ul>
                            <p className="mt-4">
                                نحن نؤمن بأن المعرفة هي القوة، ومن خلال فحصنا الشامل، نمنحك القدرة على اتخاذ قرارات  بشأن استثمارك في السيارة.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-blue-600 mb-6">لماذا تختار مركزنا؟</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div>
                            <p className="mb-4">
                                في مركز البروفيسور لفحص السيارات في أبوظبي، نحن نتميز بما يلي:
                            </p>
                            <ul className="list-disc list-inside mt-4 space-y-2">
                                <li>استخدام أحدث التقنيات والمعدات لضمان دقة الفحص</li>
                                <li>فريق من الخبراء المؤهلين والمدربين على أعلى المستويات</li>
                                <li>تقارير شفافة ومفصلة تغطي جميع جوانب السيارة</li>
                                <li>خدمة عملاء استثنائية وتوجيه مخصص لكل عميل</li>
                                <li>سمعة قوية وموثوقة في سوق السيارات المحلي</li>
                            </ul>
                            <p className="mt-4">
                                نحن نفخر بكوننا الخيار الأول للعديد من سكان أبوظبي عندما يتعلق الأمر بفحص السيارات قبل الشراء.
                            </p>
                        </div>
                        <div className="bg-gray-200 p-4 rounded-lg flex items-center justify-center">
                            <img src={CarImg} alt="مركز فحص السيارات في أبوظبي" className="max-w-full h-auto rounded" />
                        </div>
                    </div>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-blue-600 mb-6">عملية الفحص لدينا</h2>
                    <p className="mb-4">
                        نتبع عملية فحص دقيقة ومنهجية لضمان عدم إغفال أي تفاصيل. تتضمن عملية الفحص لدينا الخطوات التالية:
                    </p>
                    <ol className="list-decimal list-inside mt-4 space-y-2">
                        <li>فحص خارجي شامل لجسم السيارة</li>
                        <li>فحص داخلي لجميع المكونات والأنظمة</li>
                        <li>اختبار أداء المحرك وناقل الحركة</li>
                        <li>فحص الأنظمة الإلكترونية والكهربائية</li>
                        <li>اختبار القيادة على الطريق</li>
                        <li>إعداد تقرير مفصل بالنتائج والتوصيات</li>
                    </ol>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-blue-600 mb-6">تواصل معنا</h2>
                    <p className="mb-4">
                        نحن نؤمن بأن سلامتك وراحة بالك هي أولويتنا القصوى. لذلك، نحن هنا لمساعدتك في كل خطوة من عملية فحص وشراء سيارتك.
                    </p>
                    <p className="mb-4">
                        اتصل بنا اليوم للاستفادة من خدمات مركز فحص السيارات ابوظبي. سواء كنت تبحث عن فحص شامل لسيارتك الحالية أو تحتاج إلى فحص سيارة قبل شرائها، فنحن هنا لخدمتك.
                    </p>
                    <div className="flex flex-col items-center">
                        <a href="/book">
                            <button className="mb-4 px-8 py-3 bg-blue-600 text-white text-xl font-bold rounded-lg hover:bg-blue-700 transition duration-300">
                                احجز موعدك الآن
                            </button>
                        </a>
                        <a href="https://maps.app.goo.gl/nVnooyaAdpqJkwcn8" target="_blank">
                            <button className="px-8 py-3 bg-gray-200 text-blue-600 text-xl font-bold rounded-lg hover:bg-gray-300 transition duration-300">
                                موقع مركز فحص السيارات
                            </button>
                        </a>
                    </div>
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-bold text-blue-600 mb-6">آراء عملائنا</h2>
                    <div className="grid md:grid-cols-2 gap-8">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <p className="italic mb-4">"قبل شراء سيارة، يجب عليك فحص السيارة تقنيًا وأنا أوصيكم بمركز فحص البروفيسور لفحص السيارات في أبوظبي."</p>
                            <p className="font-bold">- معاذ س.</p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <p className="italic mb-4">"فريق محترف وتقرير مفصل. أشعر بالثقة الكاملة في سيارتي الجديدة بفضل فحصهم الشامل."</p>
                            <p className="font-bold">- فاطمة م.</p>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default AbuDhabi;

